import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Home.scss";
import Dashboard from "../../componets/Dashboard/Dashboard";
import Sidebar from "../../componets/Sidebar/Sidebar";
import Topbar from "../../componets/Topbar/Topbar";
import BeHappy from "../../componets/BeHappy/BeHappy";
import Faq from "../../componets/Faq/Faq";
import ProfileBlocks from "../../componets/Profile/ProfileBlocks/ProfileBlocks";
import MyCourses from "../../componets/Profile/MyCourses/MyCourses";
import MyPackage from "../../componets/Profile/MyPackage/MyPackage";
import OpenBill from "../../componets/Profile/OpenBill/OpenBill";
import PersonalInfo from "../../componets/Profile/PersonalInfo/PersonalInfo";
import TeacherBlocks from "../../componets/Profile/Teacher/TeacherBlocks/TeacherBlocks";
import AddSalary from "../../componets/Profile/Teacher/AddSalary/AddSalary";
import ChecklistTeacher from "../../componets/Profile/Teacher/ChecklistTeacher/ChecklistTeacher";
import MyTask from "../../componets/Profile/Teacher/MyTask/MyTask";
import TeacherCourse from "../../componets/Profile/Teacher/TeacherCourse/TeacherCourse";
import TeacherPerformance from "../../componets/Profile/Teacher/TeacherPerformance/TeacherPerformance";
import TeacherCourseDetail from "../../componets/Profile/Teacher/TeacherCourseDetail/TeacherCourseDetail";
import CourseBlocks from "../../componets/Course/CourseBlocks/CourseBlocks";
import BookCourse from "../../componets/Course/BookCourse/BookCourse";
import PlaceAbsence from "../../componets/Course/PlaceAbsence/PlaceAbsence";
import AbsenceOverview from "../../componets/Course/AbsenceOverview/AbsenceOverview";
import MessageBlocks from "../../componets/Messages/MessageBlocks/MessageBlocks";
import Chat from "../../componets/Messages/Chat/Chat";
import MessagesAndNotification from "../../componets/Messages/MessagesAndNotification/MessagesAndNotification";
import MediaBlocks from "../../componets/Media/MediaBlocks/MediaBlocks";
import Video from "../../componets/Media/Video/Video";
import MusicPlaylist from "../../componets/Media/MusicPlaylist/MusicPlaylist";
import Exercise from "../../componets/Media/Exercise/Exercise";
import News from "../../componets/Media/News/News";
import NewsDetail from "../../componets/Media/NewsDetail/NewsDetail";
import ChecklistTeacherAbschluss from "../../componets/Profile/Teacher/ChecklistTeacherAbschluss/ChecklistTeacherAbschluss";
import ApplyCourse from "../../componets/Course/ApplyCourse/ApplyCourse";
import Language from "../../componets/Profile/Language/Language";
import ChecklistTeacherVorbereitung from "../../componets/Profile/Teacher/ChecklistTeacherVorbereitung/ChecklistTeacherVorbereitung";
import EnrollCouple from "../../componets/Course/EnrollCouple/EnrollCouple";
import ChatList from "../../componets/Messages/ChatList/ChatList";
import ViewNotes from "../../componets/Profile/Teacher/ViewNotes/ViewNotes";
import ProcessList from "../../componets/Profile/Teacher/ProcessList/ProcessList";
import CourseDetail from "../../componets/Profile/CourseDetail/CourseDetail";
import QrReader from "react-qr-scanner";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import CheckedIn from "../../componets/CheckedIn/CheckedIn";
import CourseOverviewTab from "../../componets/Course/CourseOverviewTab/CourseOverviewTab";
import Loyalty from "../../componets/Loyalty/Loyalty";
import Protokoll from "../../componets/Profile/Teacher/Protokoll/Protokoll";
import DepartmentTask from "../../componets/Profile/Teacher/DepartmentTask/DepartmentTask";
import ChecklistCeo from "../../componets/Profile/Teacher/ChecklistCeo/ChecklistCeo";
import ChangeCourse from "../../componets/Course/ChangeCourse/ChangeCourse";
import PauseCourse from "../../componets/Course/PauseCourse/PauseCourse";
import CancellationCourse from "../../componets/Course/CancellationCourse/CancellationCourse";
import Receipt from "../../componets/Profile/Receipt/Receipt";

const Home = () => {
  const { option } = useParams();
  const { option1 } = useParams();
  const { option2 } = useParams();
  const [toogle, setToogle] = useState(false);
  const [toogleTop, setToogleTop] = useState(false);
  const [toogleStatus, setToogleStatus] = useState(() => {
    const storedValue = localStorage.getItem("paymentCheck");
    return storedValue === "true" ? true : storedValue === "false" ? false : null;
  });
  const [showVideo, setShowVideo] = useState();
  const navigate = useNavigate();
  const keysToRemove = [
    "is_pause_student",
    "session_user_device_id",
    "session_user_level_id",
    "session_admin_name",
    "session_course_id_detail_page_forvideo",
    "session_user_updated_date",
    "session_user_country",
    "session_setting_show_pic_inside_group",
    "session_user_lastname",
    "message_absent",
    "session_language_array",
    "session_user_login_cookie",
    "session_user_package_description",
    "session_user_renewal_notify",
    "session_user_selected_type",
    "session_user_status",
    "session_admin_email",
    "session_setting_newsletter",
    "session_user_contact",
    "session_user_password",
    "session_user_payment_status",
    "session_user_address",
    "session_user_package_expired_date",
    "session_user_is_ceo",
    "session_user_school_email",
    "session_user_state",
    "session_setting_show_online_friends",
    "session_user_sender_id",
    "session_user_city",
    "session_user_id",
    "session_user_email",
    "session_user_profile_pic",
    "session_school_image",
    "session_student_teacher",
    "session_user_created_date",
    "session_premium_status_page",
    "session_setting_event",
    "session_admin_id",
    "session_setting_account",
    "session_user_package_cost",
    "session_user_gender",
    "session_user_school_name",
    "teacher_id_student",
    "session_user_type",
    "session_setting_notification",
    "session_language",
    "session_user_zipcode",
    "is_external_teacher",
    "review",
    "session_video_id_for_video_detail_page",
    "session_user_firstname",
    "session_school_id",
    "session_user_api_id",
    "session_user_package_duration",
    "session_school_type",
    "session_user_package_id",
    "session_user_package_activated_date",
    "session_user_profile_pic_status",
    "session_user_school_parent_id",
    "session_settings",
    "session_user_school_email",
    "session_language_array",
    "session_school_login_type",
    "session_user_login_time",
    "paymentCheckDone"
  ];
  const previewStyle = {
    height: 150,
    width: 620,
  };

  const paymentStatusCheck = async () => {
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const login_school_id = localStorage.getItem("session_school_id");
    const login_user_status = localStorage.getItem("session_user_status");

    const url = `https://www.api.salsaworldstandardclass.com/function.php/?login_action_type=${login_action_type}&status=${login_user_status}&login_user_id=${login_user_id}&f=getOpenPaymentstatus&school_id=${login_school_id}`;

    try {
      const response = await axios.get(url);
      if (response.data.status === true) {
        const hasRun = localStorage.getItem("paymentCheck");
        if (!hasRun) {
          toast.warn(response.data.message);
          localStorage.setItem("paymentCheck", "true");
          setToogleStatus(true);
        }
      } else {
        localStorage.setItem("paymentCheck", "false");
        setToogleStatus(false);
      }

      // setTimeout(() => {
      //   setToogleStatus(null);
      // }, 60000);

    } catch (error) {
      console.error("Error checking payment status:", error);
    }
  };

  const checkUserLoggedIn = async () => {
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_school_login_type");
    const login_action = localStorage.getItem("session_user_status");
    const login_time = localStorage.getItem("session_user_login_time");

    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=autoLogout&status=${login_action}&login_time=${login_time}`;
    await axios.get(api).then((resp) => {
      setShowVideo(Number(resp.data.is_video_access));
      if (resp.data.status === false && (resp.data.message === 100 || resp.data.message === 401)) {
        keysToRemove.forEach((key) => {
          localStorage.removeItem(key);
        });
        navigate("/");
      }
    });
  };

  const [qrStatus, setQrStatus] = useState(false);

  const qrSannerTrue = () => {
    setQrStatus(true);
  };

  const qrSannerFalse = () => {
    setQrStatus(false);
  };

  const handleScan = (data) => {
    if (data) {
      qrSannerFalse();
      const login_cookie = localStorage.getItem("session_user_login_cookie");
      const login_user_id = localStorage.getItem("session_user_id");
      const login_action_type = localStorage.getItem("session_user_type");

      if (data.text === "attendance") {
        const formData = new FormData();
        formData.append("student_id", login_user_id);
        const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=attendanceCheckedin&status=1`;

        axios.post(api, formData).then((resp) => {
          toast("You Are Checked In Successfully");
          navigate("/Home/checkIn");
        });
      }

      if (data.text === "loyalty") {
        const formData = new FormData();
        formData.append("student_id", login_user_id);
        const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=scanLoyaltyCodePoint&status=1`;

        axios.post(api, formData).then((resp) => {
          toast("Congratulations! You got 1 Loyalty Point.");
        });
      }
    }
  };
  const handleError = async (err) => {
    alert(err);
    qrSannerFalse();
  };

  const handleSidebarItemClick = () => {
    checkUserLoggedIn();
  };

  useEffect(() => {
    checkUserLoggedIn();
    paymentStatusCheck();
  }, []);

  return (
    <>
      <Topbar
        toogle={toogle}
        setToogle={setToogle}
        qrSannerTrue={qrSannerTrue}
        toogleTop={toogleTop}
        toogleStatus={toogleStatus}
      />
      <Sidebar toogle={toogle} setToogle={setToogle} onItemClick={handleSidebarItemClick} />
      <div className="scanner">
        {qrStatus && (
          <div className="d-flex flex-column align-items-center justify-center m-3">
            <QrReader
              delay={100}
              style={previewStyle}
              constraints={{
                video: { facingMode: "environment" },
              }}
              onError={handleError}
              onScan={handleScan}
            />
          </div>
        )}
      </div>
      <ToastContainer />
      <div className="page-wrapper">
        {/*Home Dashboard*/}
        {option === "dashboard" && !option1 && !option2 && <Dashboard />}
        {/* CheckIn */}
        {option === "checkIn" && !option1 && !option2 && (
          <CheckedIn setToogleTop={setToogleTop} />
        )}
        {/* Loyalty */}
        {option === "loyalty" && !option1 && !option2 && <Loyalty />}
        {/* BeHappy */}
        {option === "beHappy" && <BeHappy />}
        {/* Faq */}
        {option === "faq" && <Faq />}
        {/*Profile*/}
        {option === "profile" && !option1 && !option2 && <ProfileBlocks />}
        {/* {option === "profile" && option1 === "profileSetting" && !option2 && (<ProfileSetting />)} */}
        {option === "profile" && option1 === "personalInfo" && !option2 && (
          <PersonalInfo />
        )}
        {option === "profile" && option1 === "packages" && !option2 && (
          <MyPackage />
        )}
        {option === "profile" && option1 === "courses" && !option2 && (
          <MyCourses />
        )}
        {option === "profile" && option1 === "courses" && option2 && (
          <CourseDetail />
        )}
        {option === "language" && !option1 && !option2 && <Language />}
        {option === "profile" && option1 === "openBill" && !option2 && (
          <OpenBill />
        )}
        {/* Teacher */}
        {localStorage.getItem("session_student_teacher") !== "0" &&
          localStorage.getItem("is_external_teacher") !== "1" &&
          option === "profile" &&
          option1 === "teacher" &&
          !option2 && <TeacherBlocks />}
        {localStorage.getItem("session_student_teacher") !== "0" &&
          localStorage.getItem("is_external_teacher") !== "1" &&
          option === "profile" &&
          option1 === "teacher" &&
          option2 === "protokoll" && <Protokoll />}
        {localStorage.getItem("session_student_teacher") !== "0" &&
          localStorage.getItem("is_external_teacher") !== "1" &&
          option === "profile" &&
          option1 === "teacher" &&
          option2 === "departmentTask" && <DepartmentTask />}
        {localStorage.getItem("session_student_teacher") !== "0" &&
          localStorage.getItem("is_external_teacher") !== "1" &&
          option === "profile" &&
          option1 === "teacher" &&
          option2 === "checklistCeo" && <ChecklistCeo />}
        {localStorage.getItem("session_student_teacher") !== "0" &&
          localStorage.getItem("is_external_teacher") !== "1" &&
          option === "profile" &&
          option1 === "teacher" &&
          option2 === "teacherCourse" && <TeacherCourse />}
        {option === "profile" &&
          option1 === "teacherCourseDetail" &&
          option2 && <TeacherCourseDetail />}
        {localStorage.getItem("session_student_teacher") !== "0" &&
          localStorage.getItem("is_external_teacher") !== "1" &&
          option === "profile" &&
          option1 === "teacher" &&
          option2 === "myTask" && <MyTask />}
        {localStorage.getItem("session_student_teacher") !== "0" &&
          localStorage.getItem("is_external_teacher") !== "1" &&
          option === "profile" &&
          option1 === "teacher" &&
          option2 === "addSalary" && <AddSalary />}
        {localStorage.getItem("session_student_teacher") !== "0" &&
          localStorage.getItem("is_external_teacher") !== "1" &&
          option === "profile" &&
          option1 === "teacher" &&
          option2 === "checklistTeacher" && <ChecklistTeacher />}
        {localStorage.getItem("session_student_teacher") !== "0" &&
          localStorage.getItem("is_external_teacher") !== "1" &&
          option === "profile" &&
          option1 === "teacher" &&
          option2 === "vorbereitung" && <ChecklistTeacherVorbereitung />}
        {localStorage.getItem("session_student_teacher") !== "0" &&
          localStorage.getItem("is_external_teacher") !== "1" &&
          option === "profile" &&
          option1 === "teacher" &&
          option2 === "abschluss" && <ChecklistTeacherAbschluss />}
        {localStorage.getItem("session_student_teacher") !== "0" &&
          localStorage.getItem("is_external_teacher") !== "1" &&
          option === "profile" &&
          option1 === "teacher" &&
          option2 === "teacherPerformance" && <TeacherPerformance />}
        {localStorage.getItem("session_student_teacher") !== "0" &&
          localStorage.getItem("is_external_teacher") !== "1" &&
          option === "profile" &&
          option1 === "viewNotes" &&
          option2 && <ViewNotes />}
        {localStorage.getItem("session_student_teacher") !== "0" &&
          localStorage.getItem("is_external_teacher") !== "1" &&
          option === "profile" &&
          option1 === "processList" &&
          option2 && <ProcessList />}
        {/*Course*/}
        {option === "course" && !option1 && !option2 && <CourseBlocks />}
        {option === "course" && option1 === "absenceOverview" && !option2 && (
          <AbsenceOverview />
        )}


        {/* New Routes Added Here */}
        {option === "course" && option1 === "changeCourse" && !option2 && (
          <ChangeCourse />
        )}
        {option === "course" && option1 === "pauseCourse" && !option2 && (
          <PauseCourse />
        )}
        {option === "profile" && option1 === "cancellationCoursePackage" && !option2 && (
          <CancellationCourse />
        )}
        {option === "profile" && option1 === "receipt" && !option2 && (
          <Receipt />
        )}


        {option === "course" && option1 === "placeAbsence" && !option2 && (
          <PlaceAbsence />
        )}
        {option === "course" && option1 === "bookCourse" && !option2 && (
          <BookCourse />
        )}
        {option === "course" && option1 === "overviewCourse" && !option2 && (
          <CourseOverviewTab />
        )}
        {option === "course" && option1 === "applyCourse" && option2 && (
          <ApplyCourse />
        )}
        {option === "course" && option1 === "enrollCouple" && option2 && (
          <EnrollCouple />
        )}
        {/*Message*/}
        {option === "message" && !option1 && !option2 && <MessageBlocks />}
        {option === "message" &&
          option1 === "messageAndNotification" &&
          !option2 && <MessagesAndNotification />}
        {option === "message" && option1 === "chat" && option2 && <Chat />}
        {option === "message" && option1 === "chatLsit" && !option2 && (
          <ChatList />
        )}
        {/*Events*/}
        {/* {option === "events" && !option1 && <Events />}
        {option === "events" && option1 && <EventsDetail />} */}
        {/*Media*/}
        {option === "media" && !option1 && !option2 && (
          <MediaBlocks showVideo={showVideo} />
        )}
        {option === "media" && option1 === "video" && !option2 && <Video />}
        {option === "media" && option1 === "musicPlaylist" && !option2 && (
          <MusicPlaylist />
        )}
        {option === "media" && option1 === "news" && !option2 && <News />}
        {option === "media" && option1 === "news" && option2 && <NewsDetail />}
        {option === "media" && option1 === "exercise" && !option2 && (
          <Exercise />
        )}
      </div>
    </>
  );
};

export default Home;
