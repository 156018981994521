import React, { useEffect, useState } from "react";
import "./PauseCourse.scss";
import axios from "axios";
import right from "../../../assets/images/icons/right-arrow.svg";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const PauseCourse = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDateChange = (e) => {
    const selectedDate = e.target.value;

    // Validate the selected date
    if (!selectedDate) {
      toast("Please select a valid start date.");
      return;
    }

    // Set the start date
    setStartDate(selectedDate);

    // Calculate the end date as 3 weeks from the selected start date
    const startDateObj = new Date(selectedDate);
    if (isNaN(startDateObj.getTime())) {
      toast("Invalid start date. Please select a valid date.");
      return;
    }

    const endDateObj = new Date(startDateObj);
    endDateObj.setDate(startDateObj.getDate() + 21); // Add 3 weeks (21 days)

    // Set the end date in the correct format (YYYY-MM-DD)
    setEndDate(endDateObj.toISOString().split("T")[0]);
  };

  const pauseCourse = async () => {
    console.log(startDate, endDate)

    const formData = new FormData();
    formData.append("pause_start", startDate);
    formData.append("pause_end", endDate);

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const login_school_id = localStorage.getItem("session_school_id");
    const login_user_status = localStorage.getItem("session_user_status");

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const currentDate = new Date();

    // Format today's date
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const todaysFormattedDate = new Date(`${year}-${month}-${day}`);

    // Validate minimum 3 weeks
    const minAllowedDate = new Date(startDateObj);
    minAllowedDate.setDate(minAllowedDate.getDate() + 21); // 3 weeks from start date

    // Validate maximum 6 months
    const maxAllowedDate = new Date(startDateObj);
    maxAllowedDate.setMonth(maxAllowedDate.getMonth() + 7); // 6 months from start date

    if (!startDate) {
      toast("Please Select Start Date");
      return;
    }
    if (!endDate) {
      toast("Please Select End Date");
      return;
    }

    if (startDateObj > endDateObj) {
      toast("End Date Is Before The Start Date. Please Select Date Properly.");
      return;
    }
    if (endDateObj < minAllowedDate) {
      toast("End date must be at least 3 weeks from the start date.");
      return;
    }
    if (endDateObj > maxAllowedDate) {
      toast("End date cannot be more than 6 months from the start date.");
      return;
    }
    if (startDateObj < todaysFormattedDate) {
      toast("You Can't Place Absence For Date Before Today.");
      return;
    }

    const url = `https://www.api.salsaworldstandardclass.com/function.php/?&status=${login_user_status}&login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=addPauseStudentRequest&school_id=${login_school_id}`;

    await axios.post(url, formData).then((resp) => {
      if (resp.data.status === true) {
        toast.success(resp.data.message);
        setStartDate("");
        setEndDate("");
      } else {
        toast.error(resp.data.message);
      }
    });
  };

  const [data, setData] = useState([]);

  const pauseHistoryDetails = async () => {
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const login_user_status = localStorage.getItem("session_user_status");
    const login_school_id = localStorage.getItem("session_school_id");

    try {
      const resp = await axios.post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_action_type=${login_action_type}&status=${login_user_status}&login_user_id=${login_user_id}&f=myPauseStudentRequestList&school_id=${login_school_id}`
      );

      if (resp?.data?.data?.length > 0) {
        setData(resp?.data?.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    pauseHistoryDetails();
  }, []);

  return (
    <div className="place_absence_section">
      <ToastContainer />
      <div className="container-fluid">
        <div className="user_detail">
          <div className="place_absence_title">
            <h2>Pause Course</h2>
          </div>
        </div>
        <div className="place_absence">
          <div className="place_absence_inner">
            <div className="place_absence_calender">
              <form className="row" >
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputStartdate" className="form-label">
                    SELECT <span>START</span> DATE
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="inputStartdate"
                    value={startDate}
                    min={new Date().toISOString().split("T")[0]}
                    onChange={handleStartDateChange}
                  />
                </div>
                <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                  <div className="arrow_img">
                    <img className="img-fluid" src={right} alt="" />
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputEnddate" className="form-label">
                    SELECT <span>END</span> DATE
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="inputEnddate"
                    value={endDate}
                    min={startDate ? new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 21)).toISOString().split("T")[0] : new Date().toISOString().split("T")[0]}
                    max={startDate ? new Date(new Date(startDate).setMonth(new Date(startDate).getMonth() + 6)).toISOString().split("T")[0] : ""}
                    onChange={(e) => {
                      const selectedDate = e.target.value;
                      setEndDate(selectedDate);
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="absent_btn mt-5">
              <button className="btn btn_primary" type="submit" onClick={pauseCourse} >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="courses_section">
        <div className="container-fluid">
          <div className="my_courses">
            <div className="user_detail">
              <div className="courses_title">
                <h2>Pause History</h2>
              </div>
            </div>
            <div className="courses">
              <div className="courses_inner">
                <div className="courses_cards row">
                  {data?.length ? (
                    data?.map((item, index) => (
                      <Link
                        className="courses_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                        key={`${index}-Card`}
                      >
                        <div className="courses_card_inner">
                          <div className="courses_detail">
                            <ul className="courses_list">
                              <li className="courses_item">
                                <span>Start Date :</span>
                                <span>
                                  {item?.start_date}
                                </span>
                              </li>
                              <li className="courses_item">
                                <span>End Date :</span>
                                <span>
                                  {item?.end_date}
                                </span>
                              </li>
                              <li className="courses_item">
                                <span>Requested Date :</span>
                                <span>
                                  {item?.requested_date}
                                </span>
                              </li>
                              {/* <li className="courses_item">
                                <span>Status :</span>
                                <span>
                                  {item?.status}
                                </span>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <Link
                      className="courses_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                    >
                      <div className="courses_card_inner">
                        <div className="courses_detail">
                          <ul className="courses_list">
                            <li className="courses_item">
                              <span>No Pause History Available</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PauseCourse;