// import React, { useEffect, useState } from "react";
// import img_usr from "../../../assets/images/personal_info/img_usr.png";
// import "./CancellationCourse.scss";
// import axios from "axios";
// import { toast } from "react-toastify";
// import { Link } from "react-router-dom";

// const CancellationCourse = () => {
//   const [CancelCourseModal, setCancelCourseModal] = useState(false);
//   const [data, setData] = useState("");

//   // Student Course
//   const [studentCourseList, setStudentCourseList] = useState([]);
//   const [studentCourseToogle, setStudentCourseToogle] = useState(false);
//   const [studentCourseToogleValue, setStudentCourseToogleValue] = useState(null);
//   const [studentCourseToogleId, setStudentCourseToogleId] = useState(null);
//   const [studentCourseToogleDate, setStudentCourseToogleDate] = useState(null);
//   console.log("🚀 ~ CancellationCourse ~ studentCourseToogleDate:", studentCourseToogleDate)

//   const [selectDate, setSelectDate] = useState(studentCourseToogleDate || "");
//   console.log("🚀 ~ CancellationCourse ~ selectDate:", selectDate)
//   const [reason, setReason] = useState("");

//   useEffect(() => {
//     setSelectDate(studentCourseToogleDate || "");
//   }, [studentCourseToogleDate]);

//   const getDropDownData = async () => {
//     try {
//       const login_user_id = localStorage.getItem("session_user_id");
//       const login_school_id = localStorage.getItem("session_school_id");
//       const login_action_type = localStorage.getItem("session_user_type");

//       const formData = new FormData();
//       formData.append("school_id", login_school_id);
//       const response = await axios.post(
//         `https://www.api.salsaworldstandardclass.com/function.php/?login_action_type=${login_action_type}&status=Active&login_user_id=${login_user_id}&f=getMyCourses&school_id=${login_school_id}`,
//         formData
//       );
//       setStudentCourseList(response?.data?.mycourses);
//     } catch (error) {
//       console.error("Error fetching dropdown list:", error);
//     }
//   };

//   const getCancelCourseDetails = async (task_id) => {
//     const login_user_id = localStorage.getItem("session_user_id");
//     const login_action_type = localStorage.getItem("session_user_type");
//     const login_school_id = localStorage.getItem("session_school_id");

//     const formData = new FormData();
//     formData.append("task_id", task_id);
//     formData.append("type", "status_check");
//     formData.append("value", "closed");
//     formData.append("action_type", "salsa_task");
//     await axios
//       .post(
//         `https://www.api.salsaworldstandardclass.com/function.php/?login_action_type=${login_action_type}&status=Active&login_user_id=${login_user_id}&f=getCancellationStudent&school_id=${login_school_id}`,
//         formData
//       )
//       .then((resp) => {
//         setData(resp.data);
//       });
//   };

//   const postChangCourseDetails = async (e) => {
//     console.table(
//       selectDate,
//       reason,
//       studentCourseToogleValue)
//     e.preventDefault();
//     const login_user_id = localStorage.getItem("session_user_id");
//     const login_action_type = localStorage.getItem("session_user_type");
//     const login_school_id = localStorage.getItem("session_school_id");

//     if (
//       !selectDate ||
//       !reason ||
//       !studentCourseToogleValue
//     ) {
//       toast.info("All Fields Are Mandatory");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("student_inacivedate", selectDate);
//     formData.append("course_description", reason);
//     formData.append("student_course_id", studentCourseToogleId);

//     try {
//       const response = await axios.post(
//         `https://www.api.salsaworldstandardclass.com/function.php/?login_action_type=${login_action_type}&status=Active&login_user_id=${login_user_id}&f=addCancellationStudent&school_id=${login_school_id}`,
//         formData
//       );

//       if (response.data.status === true) {
//         toast.success(response.data.message);
//         setCancelCourseModal(false);
//         getCancelCourseDetails();
//       } else {
//         toast.error(response.data.message);
//       }
//     } catch (error) {
//       console.error("API Error:", error);
//       toast.error("Failed to submit data. Please check your connection.");
//     }
//   };

//   useEffect(() => {
//     getDropDownData();
//   }, [studentCourseToogle]);

//   useEffect(() => {
//     getCancelCourseDetails();
//   }, []);

//   const handleCancel = () => {
//     setCancelCourseModal(false);
//     setSelectDate("");
//     setReason("");
//     setStudentCourseToogleValue(null);
//     setStudentCourseToogleDate(null);
//   };

//   return (
//     <>
//       {CancelCourseModal ? (
//         <div className="protokoll_section">
//           <div className="container-fluid">
//             <div className="user_detail">
//               <div className="protokoll_title">
//                 <h2>CANCELLATION COURSE PACKAGE</h2>
//                 {/* <div className="alert alert-danger mt-2" role="alert">
//                   ⚠ Changing the course is dangerous! Proceed with caution.
//                 </div> */}
//               </div>
//             </div>
//             <div className="protokoll_card">
//               <div className="protokoll_card_inner">
//                 <div className="protokoll_form">
//                   <form className="row">
//                     <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
//                       <label htmlFor="inputStudentCourse" className="form-label">
//                         Courses
//                       </label>
//                       <div className="type-select">
//                         <button
//                           className={`btn dropdown-toggle4 ${studentCourseToogle && "show"}`}
//                           type="button"
//                           data-toggle="dropdown"
//                           aria-haspopup="true"
//                           aria-expanded="false"
//                           onClick={() => {
//                             setStudentCourseToogle(!studentCourseToogle);
//                             // getDropDownData();
//                           }}
//                         >
//                           {studentCourseToogleValue === null ? "Select Course" : studentCourseToogleValue}
//                         </button>
//                         <ul className={`dropdown-menu ${studentCourseToogle && "show"}`}>
//                           {studentCourseList?.length > 0 ? (
//                             studentCourseList?.map((item, index) => (
//                               <li key={index}>
//                                 <Link
//                                   className="dropdown-item"
//                                   href="#"
//                                   onClick={() => {
//                                     setStudentCourseToogle(false);
//                                     setStudentCourseToogleValue(item?.course_title);
//                                     setStudentCourseToogleId(item?.course_id);
//                                     setStudentCourseToogleDate(item?.expired_date);
//                                   }}
//                                 >
//                                   {item?.course_title || ""}
//                                 </Link>
//                               </li>
//                             ))
//                           ) : (
//                             <li className="dropdown-item">No options</li>
//                           )}
//                         </ul>
//                       </div>
//                     </div>
//                     <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
//                       <label for="inputDate" className="form-label">
//                         End Date
//                       </label>
//                       <input
//                         type="date"
//                         className="form-control"
//                         id="inputDate"
//                         value={selectDate}
//                         min={new Date().toISOString().split("T")[0]}
//                         max={new Date(new Date().setFullYear(new Date().getFullYear() + 100)).toISOString().split("T")[0]}
//                         onChange={(e) => {
//                           e.target.showPicker?.();
//                           const selectedDate = e.target.value;
//                           const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 100)).toISOString().split("T")[0];
//                           if (selectedDate > maxDate) {
//                             toast("Date cannot be in the future!");
//                             return;
//                           }
//                           setSelectDate(selectedDate);
//                         }}
//                         onClick={(e) => {
//                           e.target.showPicker?.();
//                           setStudentCourseToogle(false);
//                         }}
//                         onFocus={() => {
//                           setStudentCourseToogle(false);
//                         }}
//                       />
//                     </div>

//                     <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
//                       <label htmlFor="inputReason" className="form-label">
//                         Reason
//                       </label>
//                       <textarea
//                         className="form-control"
//                         id="inputReason"
//                         rows="4"
//                         placeholder="Please enter reason"
//                         value={reason}
//                         onChange={(e) => {
//                           setReason(e.target.value);
//                         }}
//                       ></textarea>
//                     </div>
//                     <div className="d-flex justify-content-start gap-3">
//                       <button
//                         className="btn btn_primary"
//                         onClick={handleCancel}
//                       >
//                         Cancel
//                       </button>
//                       <button
//                         className="btn btn_primary"
//                         onClick={postChangCourseDetails}
//                       >
//                         Submit
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div >
//         </div >
//       ) : (
//         <div className="task_section">
//           <div className="container-fluid">
//             <div className="user_detail">
//               <div className="task_title text-center justify-content-between align-items-center flex-wrap d-flex">
//                 <h2>CANCELLATION COURSE LIST</h2>
//                 <div>
//                   <button
//                     className="btn btn_primary"
//                     onClick={() => setCancelCourseModal(true)}
//                   >
//                     Cancel Course
//                   </button>
//                 </div>
//               </div>
//             </div>
//             <div className="task_inner">
//               <div className="task_table">
//                 <div className="table-responsive">
//                   <table className="table table-bordered">
//                     <thead>
//                       <tr>
//                         <th>Courses</th>
//                         <th>End Date</th>
//                         <th>Reason</th>
//                       </tr>
//                     </thead>
//                     <tbody
//                       className="table-group-divider"
//                     >
//                       {data?.data?.length ? (
//                         data?.data?.map((course) => (
//                           <tr key={course.id}>
//                             <td>{course?.course_title}</td>
//                             <td>{course?.student_inactivedate}</td>
//                             <td>{course?.reason}</td>
//                           </tr>
//                         ))
//                       ) : (
//                         <tr>
//                           <td colSpan={9}>No Cancellation Courses Available</td>
//                         </tr>
//                       )}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default CancellationCourse;


import React, { useEffect, useState, useRef } from "react";
import img_usr from "../../../assets/images/personal_info/img_usr.png";
import "./CancellationCourse.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

const DatePicker = ({ value, onChange, minDate, maxDate }) => {
  const dateInputRef = useRef(null);

  useEffect(() => {
    const flatpickrInstance = Flatpickr(dateInputRef.current, {
      dateFormat: "Y-m-d",
      defaultDate: value || "dd/mm/yy",
      minDate: minDate || "today",
      maxDate: maxDate || new Date().fp_incr(36500),
      onChange: (selectedDates, dateStr) => {
        onChange(dateStr);
      },
    });

    return () => {
      flatpickrInstance.destroy();
    };
  }, [value, onChange, minDate, maxDate]);

  return <input type="text" className="form-control" ref={dateInputRef} placeholder="dd/mm/yy" />;
};

const CancellationCourse = () => {
  const [CancelCourseModal, setCancelCourseModal] = useState(false);
  const [data, setData] = useState("");

  // Student Course
  const [studentCourseList, setStudentCourseList] = useState([]);
  const [studentCourseToogle, setStudentCourseToogle] = useState(false);
  const [studentCourseToogleValue, setStudentCourseToogleValue] = useState(null);
  const [studentCourseToogleId, setStudentCourseToogleId] = useState(null);
  const [studentCourseToogleDate, setStudentCourseToogleDate] = useState(null);
  const [selectDate, setSelectDate] = useState(studentCourseToogleDate || "");
  const [reason, setReason] = useState("");

  useEffect(() => {
    setSelectDate(studentCourseToogleDate || "");
  }, [studentCourseToogleDate]);

  const getDropDownData = async () => {
    try {
      const login_user_id = localStorage.getItem("session_user_id");
      const login_school_id = localStorage.getItem("session_school_id");
      const login_action_type = localStorage.getItem("session_user_type");

      const formData = new FormData();
      formData.append("school_id", login_school_id);
      const response = await axios.post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_action_type=${login_action_type}&status=Active&login_user_id=${login_user_id}&f=getMyCourses&school_id=${login_school_id}`,
        formData
      );
      setStudentCourseList(response?.data?.mycourses);
    } catch (error) {
      console.error("Error fetching dropdown list:", error);
    }
  };

  const getCancelCourseDetails = async (task_id) => {
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const login_school_id = localStorage.getItem("session_school_id");

    const formData = new FormData();
    formData.append("task_id", task_id);
    formData.append("type", "status_check");
    formData.append("value", "closed");
    formData.append("action_type", "salsa_task");
    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_action_type=${login_action_type}&status=Active&login_user_id=${login_user_id}&f=getCancellationStudent&school_id=${login_school_id}`,
        formData
      )
      .then((resp) => {
        setData(resp.data);
      });
  };

  const postChangCourseDetails = async (e) => {
    e.preventDefault();
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const login_school_id = localStorage.getItem("session_school_id");

    if (!selectDate || !reason || !studentCourseToogleValue) {
      toast.info("All Fields Are Mandatory");
      return;
    }

    const formData = new FormData();
    formData.append("student_inacivedate", selectDate);
    formData.append("course_description", reason);
    formData.append("student_course_id", studentCourseToogleId);

    try {
      const response = await axios.post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_action_type=${login_action_type}&status=Active&login_user_id=${login_user_id}&f=addCancellationStudent&school_id=${login_school_id}`,
        formData
      );

      if (response.data.status === true) {
        toast.success(response.data.message);
        setCancelCourseModal(false);
        getCancelCourseDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Failed to submit data. Please check your connection.");
    }
  };

  useEffect(() => {
    getDropDownData();
  }, [studentCourseToogle]);

  useEffect(() => {
    getCancelCourseDetails();
  }, []);

  const handleCancel = () => {
    setCancelCourseModal(false);
    setSelectDate("");
    setReason("");
    setStudentCourseToogleValue(null);
    setStudentCourseToogleDate(null);
  };

  return (
    <>
      {CancelCourseModal ? (
        <div className="protokoll_section">
          <div className="container-fluid">
            <div className="user_detail">
              <div className="protokoll_title">
                <h2>CANCELLATION COURSE PACKAGE</h2>
              </div>
            </div>
            <div className="protokoll_card">
              <div className="protokoll_card_inner">
                <div className="protokoll_form">
                  <form className="row">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                      <label htmlFor="inputStudentCourse" className="form-label">
                        Courses
                      </label>
                      <div className="type-select">
                        <button
                          className={`btn dropdown-toggle4 ${studentCourseToogle && "show"}`}
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            setStudentCourseToogle(!studentCourseToogle);
                          }}
                        >
                          {studentCourseToogleValue === null ? "Select Course" : studentCourseToogleValue}
                        </button>
                        <ul className={`dropdown-menu ${studentCourseToogle && "show"}`}>
                          {studentCourseList?.length > 0 ? (
                            studentCourseList?.map((item, index) => (
                              <li key={index}>
                                <Link
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => {
                                    setStudentCourseToogle(false);
                                    setStudentCourseToogleValue(item?.course_title);
                                    setStudentCourseToogleId(item?.course_id);
                                    setStudentCourseToogleDate(item?.expired_date);
                                  }}
                                >
                                  {item?.course_title || ""}
                                </Link>
                              </li>
                            ))
                          ) : (
                            <li className="dropdown-item">No options</li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                      <label htmlFor="inputDate" className="form-label">
                        End Date
                      </label>
                      <DatePicker
                        value={selectDate}
                        onChange={(date) => {
                          const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 100)).toISOString().split("T")[0];
                          if (date > maxDate) {
                            toast("Date cannot be in the future!");
                            return;
                          }
                          setSelectDate(date);
                        }}
                        minDate={new Date().toISOString().split("T")[0]}
                        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 100)).toISOString().split("T")[0]}
                      />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <label htmlFor="inputReason" className="form-label">
                        Reason
                      </label>
                      <textarea
                        className="form-control"
                        id="inputReason"
                        rows="4"
                        placeholder="Please enter reason"
                        value={reason}
                        onChange={(e) => {
                          setReason(e.target.value);
                        }}
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-start gap-3">
                      <button
                        className="btn btn_primary"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn_primary"
                        onClick={postChangCourseDetails}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="task_section">
          <div className="container-fluid">
            <div className="user_detail">
              <div className="task_title text-center justify-content-between align-items-center flex-wrap d-flex">
                <h2>CANCELLATION COURSE LIST</h2>
                <div>
                  <button
                    className="btn btn_primary"
                    onClick={() => setCancelCourseModal(true)}
                  >
                    Cancel Course
                  </button>
                </div>
              </div>
            </div>
            <div className="task_inner">
              <div className="task_table">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Courses</th>
                        <th>End Date</th>
                        <th>Reason</th>
                      </tr>
                    </thead>
                    <tbody className="table-group-divider">
                      {data?.data?.length ? (
                        data?.data?.map((course) => (
                          <tr key={course.id}>
                            <td>{course?.course_title}</td>
                            <td>{course?.student_inactivedate}</td>
                            <td>{course?.reason}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={9}>No Cancellation Courses Available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CancellationCourse;