import React, { useEffect, useState } from "react";
import "./ChangeCourse.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const ChangeCourse = () => {
  const [ChangeCourseModal, setChangeCourseModal] = useState(false);
  const [data, setData] = useState("");

  // Type
  const [typeList, setTypeList] = useState([
    { id: 1, label: "Beginner", value: "1" },
    { id: 2, label: "Intermediate", value: "2" },
    { id: 3, label: "Advanced", value: "3" },
  ]);
  const [typeToogle, setTypeToogle] = useState(false);
  const [typeToogleValue, setTypeToogleValue] = useState(null);
  const [typeToogleId, setTypeToogleId] = useState(null);

  // Student Course
  const [studentCourseList, setStudentCourseList] = useState([]);
  const [studentCourseToogle, setStudentCourseToogle] = useState(false);
  const [studentCourseToogleValue, setStudentCourseToogleValue] = useState(null);
  const [studentCourseToogleId, setStudentCourseToogleId] = useState(0);

  // Student Future Course
  const [studentFutureCourseList, setStudentFutureCourseList] = useState([]);
  const [studentFutureCourseToogle, setStudentFutureCourseToogle] = useState(false);
  const [studentFutureCourseToogleValue, setStudentFutureCourseToogleValue] = useState(null);
  const [studentFutureCourseToogleId, setStudentFutureCourseToogleId] = useState(null);

  const [selectDate, setSelectDate] = useState("");

  // Handle category selection
  const handleCategoryChange = (item) => {
    setTypeToogle(false);
    setTypeToogleValue(item.label);
    setTypeToogleId(item.id);
  };

  // Handle student course selection
  const handleStudentCourseChange = (item) => {
    setStudentCourseToogle(false);
    setStudentCourseToogleValue(item.course_title);
    setStudentCourseToogleId(item.course_id);
  };

  // Handle future course selection
  const handleFutureCourseChange = (item) => {
    setStudentFutureCourseToogle(false);
    setStudentFutureCourseToogleValue(item.course_title);
    setStudentFutureCourseToogleId(item.course_id);
  };

  // Handle cancel
  const handleCancel = () => {
    setChangeCourseModal(false);
    setSelectDate("");
    setTypeToogleValue(null);
    setTypeToogleId(null);
    setStudentCourseToogleId(0);
    setStudentCourseToogleValue(null);
    setStudentFutureCourseToogleValue(null);
  };

  // Fetch dropdown data
  const getDropDownData = async () => {
    try {
      const login_user_id = localStorage.getItem("session_user_id");
      const login_school_id = localStorage.getItem("session_school_id");
      const login_action_type = localStorage.getItem("session_user_type");

      const formData = new FormData();
      formData.append("school_id", login_school_id);

      // Fetch current student courses
      const request1 = axios.post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_action_type=${login_action_type}&status=Active&login_user_id=${login_user_id}&f=getMyCourses&school_id=${login_school_id}`,
        formData
      );

      // Fetch future courses only if category and current course are selected
      let request2 = null;
      if (typeToogleId || studentCourseToogleId) {
        request2 = axios.post(
          `https://www.api.salsaworldstandardclass.com/function.php/?login_action_type=${login_action_type}&category=${typeToogleId}&current_course_id=${studentCourseToogleId}&status=Active&login_user_id=${login_user_id}&f=getAllCourses&school_id=${login_school_id}`,
          formData
        );
      }

      const [response1, response2] = await Promise.all([request1, request2]);
      setStudentCourseList(response1?.data?.mycourses);

      if (response2) {
        setStudentFutureCourseList(response2?.data?.allcourses);
      }
    } catch (error) {
      console.error("Error fetching dropdown list:", error);
    }
  };

  const getChangeCourseDetails = async (task_id) => {
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const login_school_id = localStorage.getItem("session_school_id");
    const login_user_status = localStorage.getItem("session_user_status");

    const formData = new FormData();
    formData.append("task_id", task_id);
    formData.append("type", "status_check");
    formData.append("value", "closed");
    formData.append("action_type", "salsa_task");
    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?&status=${login_user_status}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=myRequestList&school_id=${login_school_id}`,
        formData
      )
      .then((resp) => {
        setData(resp.data || "No Data Available");
      });
  };

  // Handle form submission
  const postChangCourseDetails = async (e) => {
    e.preventDefault();
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const login_school_id = localStorage.getItem("session_school_id");

    if (
      !selectDate ||
      !typeToogleValue ||
      !studentCourseToogleValue ||
      !studentFutureCourseToogleValue
    ) {
      toast.info("All Fields Are Mandatory");
      return;
    }

    const formData = new FormData();
    formData.append("request_deadline", selectDate);
    formData.append("type", typeToogleId);
    formData.append("course_id", studentCourseToogleId);
    formData.append("future_course_id", studentFutureCourseToogleId);

    try {
      const response = await axios.post(
        `https://www.api.salsaworldstandardclass.com/function.php/?&status=Active&login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=addRequest&school_id=${login_school_id}`,
        formData
      );

      if (response.data.status === true) {
        toast.success(response.data.message);
        setChangeCourseModal(false);
        getChangeCourseDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Failed to submit data. Please check your connection.");
    }
  };

  // Fetch data whenever typeToogleId or studentCourseToogleId changes
  useEffect(() => {
    getDropDownData();
  }, [ChangeCourseModal, typeToogle, studentCourseToogle]);

  useEffect(() => {
    getChangeCourseDetails();
  }, []);

  return (
    <>
      {ChangeCourseModal ? (
        <div className="protokoll_section">
          <div className="container-fluid">
            <div className="user_detail">
              <div className="protokoll_title">
                <h2>CHANGE COURSE</h2>
                <div className="alert alert-danger mt-2" role="alert">
                  ⚠ Please note that the course change must be completed no later than the start date of the new course. If you wish to attend a higher-level course, please discuss it with your main teacher first to ensure that it is the right level for you. <br />Thank you.
                </div>
              </div>
            </div>
            <div className="protokoll_card">
              <div className="protokoll_card_inner">
                <div className="protokoll_form">
                  <form className="row">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                      <label htmlFor="inputDate" className="form-label">
                        Date
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="inputDate"
                        value={selectDate}
                        min={new Date().toISOString().split("T")[0]}
                        max={new Date(new Date().setFullYear(new Date().getFullYear() + 100)).toISOString().split("T")[0]}
                        onChange={(e) => setSelectDate(e.target.value)}
                        onClick={(e) => { e.target.showPicker?.() }}
                      />
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                      <label htmlFor="inputCategory" className="form-label">
                        Category
                      </label>
                      <div className="type-select">
                        <button
                          className={`btn dropdown-toggle4 ${typeToogle && "show"}`}
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => setTypeToogle(!typeToogle)}
                        >
                          {typeToogleValue === null ? "Select Category" : typeToogleValue}
                        </button>
                        <ul className={`dropdown-menu ${typeToogle && "show"}`}>
                          {typeList?.length > 0 ? (
                            typeList?.map((item, index) => (
                              <li key={index}>
                                <Link
                                  className="dropdown-item"
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleCategoryChange(item);
                                  }}
                                >
                                  {item.label || ""}
                                </Link>
                              </li>
                            ))
                          ) : (
                            <li className="dropdown-item">No options</li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                      <label htmlFor="inputStudentCourse" className="form-label">
                        Student Course
                      </label>
                      <div className="type-select">
                        <button
                          className={`btn dropdown-toggle4 ${studentCourseToogle && "show"}`}
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => setStudentCourseToogle(!studentCourseToogle)}
                        >
                          {studentCourseToogleValue === null ? "Select Course" : studentCourseToogleValue}
                        </button>
                        <ul className={`dropdown-menu ${studentCourseToogle && "show"}`}>
                          {studentCourseList?.length > 0 ? (
                            studentCourseList?.map((item, index) => (
                              <li key={index}>
                                <Link
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => handleStudentCourseChange(item)}
                                >
                                  {item?.course_title || ""}
                                </Link>
                              </li>
                            ))
                          ) : (
                            <li className="dropdown-item">No options</li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                      <label htmlFor="inputStudentFutureCourse" className="form-label">
                        Student Future Course
                      </label>
                      <div className="type-select">
                        <button
                          className={`btn dropdown-toggle4 ${studentFutureCourseToogle && "show"}`}
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => setStudentFutureCourseToogle(!studentFutureCourseToogle)}
                        >
                          {studentFutureCourseToogleValue === null ? "Select Future Course" : studentFutureCourseToogleValue}
                        </button>
                        <ul className={`dropdown-menu ${studentFutureCourseToogle && "show"}`}>
                          {studentFutureCourseList?.length > 0 ? (
                            studentFutureCourseList?.map((item, index) => (
                              <li key={index}>
                                <Link
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => handleFutureCourseChange(item)}
                                >
                                  {item?.course_title || ""}
                                </Link>
                              </li>
                            ))
                          ) : (
                            <li className="dropdown-item">No options</li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex justify-content-start gap-3">
                      <button className="btn btn_primary" onClick={handleCancel}>
                        Cancel
                      </button>
                      <button className="btn btn_primary" onClick={postChangCourseDetails}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="task_section">
          <div className="container-fluid">
            <div className="user_detail">
              <div className="task_title text-center justify-content-between align-items-center flex-wrap d-flex">
                <h2>Change Course List</h2>
                <div>
                  <button
                    className="btn btn_primary"
                    onClick={() => setChangeCourseModal(true)}
                  >
                    Change Course
                  </button>
                </div>
              </div>
            </div>
            <div className="task_inner">
              <div className="task_table">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Main Course</th>
                        <th>Change Course</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.length ? (
                        data?.data?.map((course) => (
                          <tr key={course.id}>
                            <td>{course?.request_deadline}</td>
                            <td>{course?.main_course_title}</td>
                            <td>{course?.future_course_title}</td>
                            <td>{course?.request_status}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={4}>No Change Courses Available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChangeCourse;