import React from "react";
import { Link } from "react-router-dom";
import apply_course from "../../../assets/images/icons/apply_course.svg";
import event_icon from "../../../assets/images/icons/event-icon.svg";
import check_list from "../../../assets/images/icons/check-list.svg";
import book_course from "../../../assets/images/icons/book-one-time-course.svg";
import pause_course from "../../../assets/images/icons/pause_course.svg";
import change_course from "../../../assets/images/icons/change_course.svg";
import "./CourseBlocks.scss";

const CourseBlocks = () => {
  return (
    <div className="course_management_blocks">
      <div className="container-fluid">
        <div className="course_management_blocks_inner">
          <div className="course_management_blocks_group">
            <div className="row">
              <div className="course_management_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="course_management_block_inner"
                  to="/Home/course/overviewCourse"
                >
                  <div className="course_management_block_icon">
                    <img
                      className="img-fluid"
                      src={apply_course}
                      alt="apply_course"
                    />
                  </div>
                  <h2>
                    {localStorage.getItem("session_language") === "en"
                      ? "Overview courses"
                      : "Übersicht Kurse"}
                  </h2>
                </Link>
              </div>
              <div className="course_management_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="course_management_block_inner"
                  to="/Home/course/bookCourse"
                >
                  <div className="course_management_block_icon">
                    <img
                      className="img-fluid"
                      src={book_course}
                      alt="apply_course"
                    />
                  </div>
                  <h2>
                    {localStorage.getItem("session_language") === "en"
                      ? "BOOK ONE-TIME COURSES"
                      : "Zusatzkurse buchen"}
                  </h2>
                </Link>
              </div>
              <div className="course_management_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="course_management_block_inner"
                  to="/Home/course/placeAbsence"
                >
                  <div className="course_management_block_icon">
                    <img
                      className="img-fluid"
                      src={event_icon}
                      alt="event_icon"
                    />
                  </div>
                  <h2>
                    {localStorage.getItem("session_language") === "en"
                      ? "Place Absence"
                      : "Absenz erfassen"}
                  </h2>
                </Link>
              </div>
              <div className="course_management_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="course_management_block_inner"
                  to="/Home/course/absenceOverview"
                >
                  <div className="course_management_block_icon">
                    <img
                      className="img-fluid"
                      src={check_list}
                      alt="check_list"
                    />
                  </div>

                  <h2>
                    {localStorage.getItem("session_language") === "en"
                      ? "Overview Absence"
                      : "Übersicht Absenzen"}
                  </h2>
                </Link>
              </div>
              <div className="course_management_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="course_management_block_inner"
                  to="/Home/course/changeCourse
"
                >
                  <div className="course_management_block_icon">
                    <img
                      className="img-fluid"
                      src={change_course}
                      alt="check_list"
                    />
                  </div>

                  <h2>
                    {localStorage.getItem("session_language") === "en"
                      ? "Change Course"
                      : "Übersicht Absenzen"}
                  </h2>
                </Link>
              </div>
              <div className="course_management_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="course_management_block_inner"
                  to="/Home/course/pauseCourse"
                >
                  <div className="course_management_block_icon">
                    <img
                      className="img-fluid"
                      src={pause_course}
                      alt="check_list"
                    />
                  </div>

                  <h2>
                    {localStorage.getItem("session_language") === "en"
                      ? "PAUSE COURSE"
                      : "Übersicht Absenzen"}
                  </h2>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseBlocks;
