import React, { useState } from "react";
import "./Receipt.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Receipt = () => {
  const [loadingId, setLoadingId] = useState(null);

  const pauseCourse = async (id) => {
    setLoadingId(id);
    try {
      const login_user_id = localStorage.getItem("session_user_id");
      const login_action_type = localStorage.getItem("session_user_type");
      const login_school_id = localStorage.getItem("session_school_id");
      const login_user_status = localStorage.getItem("session_user_status");
      const url = `https://www.api.salsaworldstandardclass.com/function.php/?login_action_type=${login_action_type}&status=${login_user_status}&login_user_id=${login_user_id}&f=studentPayemntPDF&school_id=${login_school_id}&duration=${id}`;

      const response = await axios.get(url, {
        responseType: "blob",
      });

      if (response.status === 200) {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", `Last ${id} Quittung.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(fileURL);

        toast.success("Receipt downloaded successfully!");
      } else {
        toast.error("Failed to download receipt.");
      }
    } catch (error) {
      toast.error("An error occurred while downloading the receipt.");
      console.error(error);
    } finally {
      setLoadingId(null);
    }
  };

  return (
    <div className="place_absence_section">
      <ToastContainer />
      <div className="container-fluid">
        <div className="user_detail">
          <div className="place_absence_title">
            <h2>Download Receipt</h2>
          </div>
        </div>
        <div className="place_absence">
          <div className="place_absence_inner">
            <div className="place_absence_cards row">
              <div className="place_absence_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="place_absence_card_inner">
                  <div className="place_absence_card_title">
                    <h2>Last Payment</h2>
                  </div>
                  <div className="absent_btn mt-5">
                    <button
                      className="btn btn_primary"
                      onClick={() => pauseCourse("1")}
                      disabled={loadingId === "1"}
                    >
                      {loadingId === "1" ? "Downloading..." : "Download"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="place_absence_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="place_absence_card_inner">
                  <div className="place_absence_card_title">
                    <h2>Last 3 Months Payment</h2>
                  </div>
                  <div className="absent_btn mt-5">
                    <button
                      className="btn btn_primary"
                      onClick={() => pauseCourse("3")}
                      disabled={loadingId === "3"}
                    >
                      {loadingId === "3" ? "Downloading..." : "Download"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="place_absence_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="place_absence_card_inner">
                  <div className="place_absence_card_title">
                    <h2>Last 6 Months Payment</h2>
                  </div>
                  <div className="absent_btn mt-5">
                    <button
                      className="btn btn_primary"
                      onClick={() => pauseCourse("6")}
                      disabled={loadingId === "6"}
                    >
                      {loadingId === "6" ? "Downloading..." : "Download"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Receipt;