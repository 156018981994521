import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./SignIn.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import loginBg from "../../assets/images/logo/Group 1.png";

const SignIn = () => {
  const [userType, setUserType] = useState(null);
  const [userValue, setUserValue] = useState(null);
  const [userToogle, setUserToogle] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const navigate = useNavigate();

  const loginFunction = async (e) => {
    e.preventDefault();
    const notifyInvalidCredantial = () => toast("Invalid Credantial");
    const notifyPauseStudent = () =>
      toast("Hi! Your account is currently on pause");
    const notifySchoolSelect = () => toast("Please Select School");
    const notifyAllFeild = () => toast("All Feilds Are Mendatory");
    const errMsg = (err) => toast(err);

    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("action_type", userType);
    formData.append("school", localStorage.getItem("session_school_id"));
    formData.append("status", "active");

    if (!localStorage.getItem("session_school_id")) {
      notifySchoolSelect();
      navigate("/");
    } else if (email === null || password === null || userType === null) {
      notifyAllFeild();
    } else {
      await axios
        .post(
          "https://www.api.salsaworldstandardclass.com/function.php/function.php/?login_cookie=null&login_user_id=null&login_action_type=null&f=login&status=1",
          formData
        )
        .then((respnse) => {
          if (respnse.data.status === false && respnse.data.message === 406) {
            notifyInvalidCredantial();
          } else if (
            respnse.data.status === false &&
            respnse.data.message === 100
          ) {
            errMsg(respnse.data.error_message);
          } else if (Number(respnse.data.is_pause_student) === 1) {
            notifyPauseStudent();
          } else {
            localStorage.setItem("session_school_login_type", userType);
            localStorage.setItem("session_school_id", respnse.data.school_id);
            localStorage.setItem(
              "session_user_api_id",
              respnse.data.user_api_id
            );
            localStorage.setItem(
              "session_user_package_duration",
              respnse.data.user_package_duration
            );
            localStorage.setItem(
              "session_school_type",
              respnse.data.student_school_type
            );
            localStorage.setItem(
              "session_user_package_id",
              respnse.data.user_package_id
            );
            localStorage.setItem(
              "session_user_package_activated_date",
              respnse.data.user_package_activated_date
            );
            localStorage.setItem(
              "session_user_profile_pic_status",
              respnse.data.user_profile_pic_status
            );
            localStorage.setItem(
              "session_user_school_parent_id",
              respnse.data.user_school_parent_id
            );
            // localStorage.setItem("session_settings");
            localStorage.setItem(
              "is_pause_student",
              respnse.data.is_pause_student
            );
            localStorage.setItem(
              "session_user_device_id",
              respnse.data.user_device_id
            );
            localStorage.setItem(
              "session_user_level_id",
              respnse.data.user_level_id
            );
            localStorage.setItem("session_admin_name", respnse.data.admin_name);
            // localStorage.setItem("session_course_id_detail_page_forvideo");
            localStorage.setItem(
              "session_user_updated_date",
              respnse.data.user_updated_date
            );
            localStorage.setItem(
              "session_user_country",
              respnse.data.user_country
            );
            localStorage.setItem(
              "session_setting_show_pic_inside_group",
              respnse.data.setting_show_pic_inside_group
            );
            localStorage.setItem(
              "session_user_lastname",
              respnse.data.user_lastname
            );
            // localStorage.setItem("message_absent");
            // localStorage.setItem("session_language_array");
            localStorage.setItem(
              "session_user_login_cookie",
              respnse.data.login_cookie
            );
            localStorage.setItem(
              "session_user_package_description",
              respnse.data.user_package_description
            );
            localStorage.setItem(
              "session_user_renewal_notify",
              respnse.data.user_renewal_notify
            );
            localStorage.setItem(
              "session_user_selected_type",
              respnse.data.user_selected_type
            );
            localStorage.setItem(
              "session_user_status",
              respnse.data.user_status
            );
            localStorage.setItem(
              "session_admin_email",
              respnse.data.admin_email
            );
            localStorage.setItem(
              "session_setting_newsletter",
              respnse.data.setting_newsletter
            );
            localStorage.setItem(
              "session_user_contact",
              respnse.data.user_contact
            );
            localStorage.setItem(
              "session_user_password",
              respnse.data.user_password
            );
            // localStorage.setItem("session_user_payment_status");
            localStorage.setItem(
              "session_user_address",
              respnse.data.user_address
            );
            localStorage.setItem(
              "session_user_package_expired_date",
              respnse.data.user_package_expired_date
            );
            localStorage.setItem("session_user_is_ceo", respnse.data.is_ceo);

            localStorage.setItem(
              "session_user_school_email",
              respnse.data.user_school_email
            );
            localStorage.setItem("session_user_state", respnse.data.user_state);
            localStorage.setItem(
              "session_setting_show_online_friends",
              respnse.data.setting_show_online_friends
            );
            localStorage.setItem(
              "session_user_sender_id",
              respnse.data.user_sender_id
            );
            localStorage.setItem("session_user_city", respnse.data.user_city);
            localStorage.setItem("session_user_id", respnse.data.user_id);
            localStorage.setItem("session_user_email", respnse.data.user_email);
            localStorage.setItem(
              "session_user_profile_pic",
              respnse.data.user_profile_pic
            );
            localStorage.setItem(
              "session_school_image",
              respnse.data.school_image
            );
            localStorage.setItem(
              "session_student_teacher",
              respnse.data.teacher_id_student
            );
            localStorage.setItem(
              "session_user_created_date",
              respnse.data.user_created_date
            );
            localStorage.setItem(
              "session_premium_status_page",
              respnse.data.premium_status_page
            );
            localStorage.setItem(
              "session_setting_event",
              respnse.data.setting_event
            );
            localStorage.setItem("session_admin_id", respnse.data.admin_id);
            localStorage.setItem(
              "session_setting_account",
              respnse.data.setting_account
            );
            localStorage.setItem(
              "session_user_package_cost",
              respnse.data.user_package_cost
            );
            localStorage.setItem(
              "session_user_gender",
              respnse.data.user_gender
            );
            localStorage.setItem(
              "session_user_school_name",
              respnse.data.user_school_name
            );
            localStorage.setItem(
              "teacher_id_student",
              respnse.data.teacher_id_student
            );
            localStorage.setItem("session_user_type", respnse.data.user_type);
            localStorage.setItem(
              "session_setting_notification",
              respnse.data.setting_notification
            );
            localStorage.setItem("session_language", "en");
            localStorage.setItem(
              "session_user_zipcode",
              respnse.data.user_zipcode
            );
            localStorage.setItem(
              "is_external_teacher",
              respnse.data.is_external_teacher
            );
            localStorage.setItem("review", 1);
            // localStorage.setItem("session_video_id_for_video_detail_page");
            localStorage.setItem(
              "session_user_firstname",
              respnse.data.user_firstname
            );
            localStorage.setItem(
              "session_user_login_time",
              respnse.data.login_time
            );
            navigate("/Home/dashboard");
            // paymentStatusCheck();
          }
        });
    }
  };

  if (localStorage.getItem("session_user_status") === "Active") {
    navigate("/Home/dashboard");
  }

  return (
    <>
      <section className="login_section">
        <ToastContainer />
        <div className="container">
          <div className="login_row row align-items-center">
            <div className="login_img_column">
              <div className="login_img">
                <img className="img img-fluid" src={loginBg} alt="mainImg" />
              </div>
            </div>
            <div className="login_content_column">
              <div className="signInHeading">
                <h1>Salsa World Standard Class</h1>
              </div>
              <form className="login_form" onSubmit={loginFunction}>
                <div className="custom-select">
                  <button
                    className={`btn dropdown-toggle ${userToogle && "show"}`}
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => {
                      setUserToogle(!userToogle);
                    }}
                  >
                    {userValue === null ? "Select User" : userValue}
                  </button>
                  <ul className={`dropdown-menu ${userToogle && "show"}`}>
                    <li
                      onClick={() => {
                        setUserType("student_login");
                        setUserValue("STUDENT");
                      }}
                    >
                      <Link
                        className="dropdown-item"
                        onClick={() => {
                          setUserToogle(false);
                        }}
                      >
                        STUDENT
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        setUserType("teacher_login");
                        setUserValue("TEACHER");
                      }}
                    >
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={() => {
                          setUserToogle(false);
                        }}
                      >
                        TEACHER
                      </Link>
                    </li>
                  </ul>
                </div>
                <input
                  type="text"
                  className="login_input"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Email"
                />
                <input
                  type="password"
                  className="login_input"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder="Password"
                />
                <div className="login_btn">
                  <button className="btn btn_primary" type="submit">
                    Login
                  </button>
                </div>
              </form>
              <div className="changeSchool">
                <div className="schoolChangeLink">
                  <Link to="/forgot">Forgot Password</Link>
                </div>
                <div className="schoolChangeLink">
                  <Link to="/">Change School</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="copyright">
        <p>© 2023 SALSA WORLD STANDARD CLASS</p>
      </section>
    </>
  );
};

export default SignIn;
